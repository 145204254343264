import * as PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import './Image.scss'
import { graphql, useStaticQuery } from 'gatsby'

const Image = ({ relativePath, alt, className, caption }) => {
  const data = useStaticQuery(graphql`query AllImagesQuery {
      images: allFile(filter: {absolutePath: {regex: "/assets/img/"}}) {
          nodes {
              relativePath
              childImageSharp {
                  gatsbyImageData(
                      quality: 70
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                  )
              }
          }
      }
  }
  `)
  let theImage = data.images.nodes.find(imgNode => imgNode.relativePath === relativePath)

  return (
    <div>
      <GatsbyImage image={theImage.childImageSharp.gatsbyImageData} className={className} alt={alt}/>
      {
        caption &&
        <div className="mt-3 text-caption text-black-50"><span><small>{caption}</small></span></div>
      }
    </div>
  )
}

export default Image

Image.propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.node,
  className: PropTypes.string,
  relativePath: PropTypes.string.isRequired,
}
