import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Praktikant im Bereich Softwareentwicklung (m/w/d)"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt für einen Platz als Praktikant und sammle wertvolle Erfahrungen!"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Praktikum in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>Praktikant im Bereich Softwareentwicklung (m/w/d)</h1>
          <br/>
          <h2>Deine Aufgabe</h2>

          {/*<p>Während Deines Praktikums im Bereich Softwareentwicklung tauchst Du voll und ganz in unser innovatives*/}
          {/*  Open-Source-Tool <NavigationLink*/}
          {/*    to={'https://jqassistant.org'} ariaLabel={'jQAssistant'} asTextLink>jQAssistant</NavigationLink> ein.*/}
          {/*  Dabei beschäftigst Du Dich mit der interaktiven Visualisierung von Softwarestrukturen.</p>*/}

          <p>Während Deines Praktikums im Bereich Softwareentwicklung tauchst Du voll und ganz in gängige und aktuelle
            Technologien ein. Dabei lernst Du wie Softwarelösungen nach allen Regeln der Softwaretechnik in der Praxis
            erstellt werden.</p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Im Studium der Informatik oder eines verwandten Studiengangs hast Du bereits umfassende theoretische
                Kenntnisse zu Deinem Fachgebiet erlangt, die Du nun praktisch anwenden möchtest.
              </li>

              {/*<li>Du interessierst Dich für Softwarearchitektur und -qualität.</li>*/}
              {/*<li>React und JavaScript sind keine Fremdwörter für Dich und idealerweise hast Du schon einmal mit einer*/}
              {/*  Graphendatenbank (Neo4j) oder Java gearbeitet.*/}
              {/*</li>*/}

              <li>Du bist kommunikativ, organisiert und arbeitest selbstständig.</li>
              <li>Neben guten Deutsch- und Englischkenntnissen besitzt Du soziale Kompetenz und
                Begeisterungsfähigkeit.
              </li>
            </ul>
          </p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Dich erwarten eine kompetente Einarbeitung durch einen persönlichen Mentor, sowie eine intensive
                Betreuung und regelmäßiges Feedback.
              </li>
              <li>Du sammelst praktische Erfahrungen im Umgang mit modernen Technologien.</li>
              <li>Du erhältst eine angemessene Vergütung.</li>
              <li>Nach individueller Absprache ist regelmäßiges Homeoffice kein Problem.</li>
              <li>Profitiere von unserer Kooperation mit Hochschulen wie z. B. der TU Dresden und der Universität
                Leipzig.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
              <li>Mit einem erfolgreichen Abschluss bieten wir Dir Perspektiven bei BUSCHMAIS.</li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Schreibe uns gerne eine E-Mail mit Deinen Vorstellungen und Deinem Lebenslauf. Für Rückfragen stehen wir Dir gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
